import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { PAGE_CONFIG } from '../shared/constants/page-config';
import CookieConsent from '../layout/cookie-consent';
import SEO from '../layout/seo/seo';

export default function Index() {
  useEffect(() => {
    navigate(`${PAGE_CONFIG.baseUrl}`);
  });

  return (
    <main>
      <CookieConsent/>
      <SEO/>
    </main>
  );
}
